@tailwind base;
@tailwind components;
@tailwind utilities;

/* Tokens */
:root {
  /* brand colors */
  --magenta-mousse: 187, 41, 187;
  --pink-peppermint: 225, 0, 152;

  --color-primary: 20 20 20; /* Text, buttons, etc. */
  --color-contrast: 250 250 250; /* Backgrounds, borders, etc. */
  --color-accent: 191 72 0; /* Labels like "On sale" */
  --font-size-fine: 0.75rem; /* text-xs */
  --font-size-copy: 1rem; /* text-base */
  --font-size-lead: 1.125rem; /* text-lg */
  --font-size-heading: 2rem; /* text-2xl */
  --font-size-display: 3rem; /* text-4xl */
  --color-shop-pay: #5a31f4;
  --shop-pay-button--width: 100%; /* Sets the width for the shop-pay-button web component */
  --height-nav: 3rem;
  --screen-height: 100vh;
  --screen-height-dynamic: 100vh;

  --color-button-primary: var(--magenta-mousse); /* purple popsicle */
  --color-button-contrast: var(--magenta-mousse), 0.9;

  @media (min-width: 32em) {
    --height-nav: 4rem;
  }
  @media (min-width: 48em) {
    --height-nav: 6rem;
    --font-size-heading: 2.25rem; /* text-4xl */
    --font-size-display: 3.75rem; /* text-6xl */
  }
  @supports (height: 100svh) {
    --screen-height: 100svh;
  }
  @supports (height: 100dvh) {
    --screen-height-dynamic: 100dvh;
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-primary: 250 250 250;
    --color-contrast: 32 33 36;
    --color-accent: 235 86 40;

    --color-button-primary: 91, 33, 182;
    --color-button-contrast: 139, 92, 246;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

shop-pay-button {
  width: 100%;
  height: 3rem;
  display: table;
}

@layer base {
  * {
    font-variant-ligatures: none;
  }

  body {
    @apply border-primary/10 bg-contrast text-primary/90 antialiased;
  }

  html {
    scroll-padding-top: 10rem;
  }

  model-viewer::part(default-progress-mask) {
    display: none;
  }

  model-viewer::part(default-progress-bar) {
    display: none;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }

  a.active {
    @apply border-b;
  }

  .prose {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      &:first-child {
        @apply mt-0;
      }
    }
  }
}

@layer components {
  .article {
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply font-sans text-primary;
    }
    @apply prose mx-auto mb-12 grid justify-center font-serif text-primary;
    p,
    ul,
    li {
      @apply mb-4 text-lg;
    }
    img {
      @apply md:-mx-8 lg:-mx-16;

      @media (min-width: 48em) {
        width: calc(100% + 4rem);
        max-width: 100vw;
      }
      @media (min-width: 64em) {
        width: calc(100% + 8rem);
      }
    }
  }

  .swimlane {
    @apply grid w-full snap-x snap-mandatory scroll-px-6 grid-flow-col justify-start gap-4 overflow-x-scroll px-6 pb-4;
  }

  .rdo {
    @apply
    checked:bg-buttonPrimary
    checked:hover:bg-buttonPrimary 
    checked:active:bg-buttonPrimary 
    checked:focus:bg-buttonPrimary 
    focus:bg-buttonPrimary 
    focus:outline-none 
    focus:ring-1
    focus:ring-buttonPrimary
  }

  .landingButtonPrimary {
    @apply self-start text-[24px] px-[28px] py-[17px] border-2 border-buttonPrimary hover:bg-buttonPrimary md:px-16 md:py-6 md:text-xl my-5
  }
}

@layer utilities {
  .fadeIn {
    opacity: 0;
    animation: fadeInAnimation ease 500ms forwards;
  }

  .hiddenScroll {
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .absolute-center {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }

  .strike {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 108%;
      height: 1.5px;
      left: -4%;
      top: 50%;
      transform: translateY(-50%);
      background: rgb(var(--color-primary));
      box-shadow: 0.5px 0.5px 0px 0.5px rgb(var(--color-contrast));
    }
  }

  .card-image {
    @apply relative flex items-center justify-center overflow-clip rounded;
    &::before {
      content: ' ';
      @apply absolute top-0 left-0 z-10 block h-full w-full rounded shadow-border;
    }
    img {
      @apply aspect-[inherit] w-full object-cover;
    }
  }
}
@layer custom{

@media all {
  .seller-info *, .seller-info *:before, .seller-info *:after {
    box-sizing: border-box;
  }
  .seller-info input, .seller-info button {
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
  }
  .seller-info img {
    max-width: 100%;
    border: 0;
  }
  .seller-info button, .seller-info input {
    color: inherit;
    font: inherit;
    margin: 0;
  }
  .seller-info button, .seller-info input {
    touch-action: manipulation;
  }
  .seller-info input, .seller-info button {
    font-size: 16px;
    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: 400;
    color: #3d4246;
    line-height: 1.5;
  }
}
@media only screen and (max-width: 989px) {
  .seller-info input, .seller-info button {
    font-size: 16px;
  }
}
@media all {
  .seller-info p {
    color: #69727b;
    margin: 0 0 19.44444px;
  }
}
@media only screen and (max-width: 749px) {
  .seller-info p {
    font-size: 0.9375em;
  }
}
@media all {
  .seller-info p:last-child {
    margin-bottom: 0;
  }
}
@media all {
  .seller-info .rte img {
    height: auto;
  }
}
@media all {
  .seller-info .btn {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: auto;
    text-decoration: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 2px;
    padding: 8px 15px;
    background-color: #557b97;
    color: #fff;
    font-family: Work Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.08em;
    white-space: normal;
    font-size: 14px;
  }
}
@media only screen and (min-width: 750px) {
  .seller-info .btn {
    padding: 10px 18px;
  }
}
@media all {
  .seller-info .btn:not([disabled]):hover, .seller-info .btn:focus {
    color: #fff;
    background-color: #436076;
  }
}
@media all {
  .seller-info button {
    cursor: pointer;
  }
}
@media all {
  .seller-info input {
    border: 1px solid #949494;
    background-color: #fff;
    color: #000;
    max-width: 100%;
    line-height: 1.2;
    border-radius: 2px;
  }
}
@media all {
  .seller-info input:focus {
    border-color: #7b7b7b;
  }
}
@media all {
  .seller-info input {
    padding: 8px 15px;
  }
}
@media only screen and (min-width: 750px) {
  .seller-info input {
    padding: 10px 18px;
  }
}
.seller-info ::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.seller-info ::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.seller-info .row {
  margin-left: -15px;
  margin-right: -15px;
}
.seller-info .col-sm-2, .seller-info .col-md-2, .seller-info .col-lg-2, .seller-info .col-sm-8, .seller-info .col-md-8, .seller-info .col-lg-8, .seller-info .col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}
.seller-info .col-xs-12 {
  float: left;
}
.seller-info .col-xs-12 {
  width: 100%;
}
@media (min-width: 768px) {
  .seller-info .col-sm-2, .seller-info .col-sm-8 {
    float: left;
  }
  .seller-info .col-sm-8 {
    width: 66.66666667%;
  }
  .seller-info .col-sm-2 {
    width: 16.66666667%;
  }
}
@media (min-width: 992px) {
  .seller-info .col-md-2, .seller-info .col-md-8 {
    float: left;
  }
  .seller-info .col-md-8 {
    width: 66.66666667%;
  }
  .seller-info .col-md-2 {
    width: 16.66666667%;
  }
}
@media (min-width: 1200px) {
  .seller-info .col-lg-2, .seller-info .col-lg-8 {
    float: left;
  }
  .seller-info .col-lg-8 {
    width: 66.66666667%;
  }
  .seller-info .col-lg-2 {
    width: 16.66666667%;
  }
}
.seller-info *, .seller-info *:before, .seller-info *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.seller-info .fa {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.seller-info .fa-chain:before {
  content: "";
}
.seller-info .fa-thumbs-up:before {
  content: "";
}
.seller-info .hidden {
  display: none;
}
.seller-info .mp-mrgn-btm-12 {
  margin-bottom: 12px;
}
.seller-info .mp-mrgn-btm-5 {
  margin-bottom: 5px;
}
.seller-info .mp-store-logo {
  overflow: hidden;
}
.seller-info #store_logo_image {
  padding: 3px;
  max-height: 250px;
}
.seller-info .mp-store-name {
  font-size: 20px;
  font-weight: 600;
}
.seller-info .mp-color-gray {
  color: #5F5F5F;
}
.seller-info .mp-pad-15 {
  padding: 15px;
}
.seller-info .wk-seller-profile {
  text-align: center !important;
}
.seller-info .wk-seller-logo {
  text-align: left !important;
}
@media only screen and (max-width: 600px) {
  .seller-info .wk-seller-profile {
    text-align: left !important;
  }
  .seller-info .wk-seller-logo {
    text-align: center !important;
  }
}
@media screen and (max-width: 960px) {
  .seller-info .wk-parent-logo {
    width: 100%;
    margin: 20px 0px;
  }
}
#store_logo_image{width: 65px; border-radius: 150px;}
.styled_p span{color: #000000; font-family: Verdana, Arial, Helvetica, sans-serif; font-size: 11px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;}
.seller-info {
  width: 100%;
}#store_logo_image{margin:auto;}
}

.formSearch{max-width: 250px;    align-items: center;}
.formSearch,.formSearch input{font-size: 20px;}
.color-red{color:red;}